$('.request-form form').submit(function(e) {
    e.preventDefault();

    var $form = $(e.target);
    let formData = $form.serializeArray();

    let data = new FormData();
    data.append('url', window.location.href);
    data.append('action', 'submit_contact_form');

    for (let i = 0; i < formData.length; i++) {
        let name = formData[i]['name'];
        let value = formData[i]['value'];
        data.append(name, value);
    }

    var $response = $form.find('.response');
    $response.hide();
    $response.removeClass('success error');

    $.ajax({
        url: gapo_scripts.ajax_url,
        type: 'POST',
        data : data,
        cache: false,
        processData: false,
        contentType: false,
        success: function(data) {
            var json = JSON.parse(data);
            var message = json['message'];


            if (json.status === 'success') {
                $response.addClass('success');
                $form[0].reset();
            } else {
                $response.addClass('error');
            }

            $response.text(message);
            $response.show();
        },
        error: function(response) {
            console.log(response.responseText);
            $response.html(response.responseText);
            $response.addClass('error');
            $response.show();
        }
    });
});
